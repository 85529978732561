import React from "react";
import "./section4SmallCard.scss";
import Link_Button_Blue from "../../../../common/asset/images/Link_Button_Blue.png";
import toast from "react-hot-toast";

function Section4SmallCard({cardData}) {
	const comingSoonToast = () => {
		toast('Coming soon!', {
			icon: '👏',
		});
	}
	const openGateWay = () => {
		window.open('gateway.beta.eqhub.eqbr.com')
	}
	return (
		<div id="Section4SmallCard">
			<div className="section4-small-card-content-wrapper">
				<div className="section4-small-card-title">
					{cardData.title}
				</div>
				<div className="section4-small-card-main-text">
					{cardData.mainText}
				</div>
			</div>
			{cardData.img && <img src={cardData.img} alt={"card-image"} className={"section4-small-card-main-img"}/>}

			{/*<div className="list-btn" onClick={comingSoonToast}>*/}
			{/*  <div className="round">*/}
			{/*    <div id="cta">*/}
			{/*      <span className="arrow primera next "></span>*/}
			{/*      <span className="arrow segunda next "></span>*/}
			{/*    </div>*/}
			{/*  </div>*/}
			{/*</div>*/}
		</div>
	);
}

export default Section4SmallCard;
