import React, {useRef, useState} from "react";
import Input from "../../../common/atom/input/Input";
import "./Section6.scss";
import TextArea from "../../../common/atom/input/TextArea";
import emailjs from '@emailjs/browser';
import toast from "react-hot-toast";

const SELECT_OPTIONS = [
	"Blockchain network build",
	"Nodes operation",
	"Web3 Wallet",
	"Reward system (Point & Mileage)",
	"Digital Voucher",
	"Ticketing",
	"Security Token Offering",
	"Web3 Pay",
	"etc",
];

function Section6(props) {
	const formRef = useRef();

	const [name, setName] = useState("");
	const [company, setCompany] = useState("");
	const [phone, setPhone] = useState("");
	const [email, setEmail] = useState("");
	const [interest, setInterest] = useState(SELECT_OPTIONS[0]);
	const [question, setQuestion] = useState("");

	const emailFormDatas = [
		{id: "name", title: "Name", value: name, setValue: setName, type: "text"},
		{id: "company", title: "Company", value: company, setValue: setCompany, type: "text"},
		{id: "phone", title: "Phone", value: phone, setValue: setPhone, type: "text"},
		{id: "email", title: "Email", value: email, setValue: setEmail, type: "text"},
		{id: "interest", title: "What are you interested in?", value: interest, setValue: setInterest, type: "select"},
		{id: "question", title: "What’s your question?", value: question, setValue: setQuestion, type: "textarea"},
	]

	const renderEmailForm = (emailFormData) => {
		const {id, title, value, setValue, type} = emailFormData;
		switch (type) {
			case "text":
				return (
					<div className="section6-input-container" key={"section6-input-container" + id} id={id}>
						<div className="section6-input-title">{title}</div>
						<Input
							type="text"
							className="input-contact"
							value={value}
							onChange={(e) => setValue(e.target.value)}
						/>
					</div>
				);
			case "textarea":
				return (
					<div className="section6-input-container" key={"section6-input-container" + id} id={id}>
						<div className="section6-input-title">{title}</div>
						<div className="section6-textarea-container" key={"section6-textarea-container" + id}>
							<TextArea
								className="textarea-contact"
								value={value}
								onChange={(e) => setValue(e.target.value)}
								maxLength={500}
							/>
						</div>
						<div className="section6-input-length">{value.length + "/500"}</div>

					</div>
				)
			case "select":
				return (
					<div className="section6-input-container" key={"section6-input-container" + id} id={id}>
						<div className="section6-input-title">{title}</div>
						<select
							className="select-contact"
							value={value}
							onChange={(e) => setValue(e.target.value)}
						>
							{SELECT_OPTIONS.map((option) => (
								<option key={option} value={option}>{option}</option>
							))}
						</select>
					</div>
				)
			default:
				return (
					<div className="section6-input-container" key={"section6-input-container" + id} id={id}>
						<div className="section6-input-title">{title}</div>
						<Input
							type="text"
							className="input-contact"
							value={value}
							onChange={(e) => setValue(e.target.value)}
						/>
					</div>
				);
		}
	}
	const sendEmail = (e) => {
		e.preventDefault();
		const service_id = process.env.REACT_APP_EQHUB_EMAILJS_SERVICE;
		const template_id = process.env.REACT_APP_EQHUB_EMAILJS_TEMPLATE
		const publicKey = process.env.REACT_APP_EQHUB_EMAILJS_PUBLIC_KEY;
		const templateParams = {
			name,
			company,
			phone,
			email,
			interest,
			question
		}
		toast.loading("Sending email...");
		emailjs.send(service_id, template_id, templateParams, {
			publicKey
		})
			.then((result) => {
				console.log("success", result.text);
				toast.success("Email sent successfully!");
				setName("");
				setCompany("");
				setPhone("");
				setEmail("");
				setInterest("");
				setQuestion("");
			}, (error) => {
				console.log("error", error.text);
				toast.error("Failed to send email. Please try to mail \"eqhub@eqbr.com\" directly.");
			});
	}

	return (
		<div id="Section6">
			<div className="section6-text-container">
				<div className="section6-title">Contact EQ Hub Team</div>
				<div className="section6-sub-title">
					{"Are you planning to build a Web3 business?\nContact the EQ Hub sales team to get started."}
				</div>
			</div>
			<form ref={formRef} onSubmit={(e) => sendEmail(e)} className="section6-contact-container">
				{emailFormDatas.map((emailFormData) => renderEmailForm(emailFormData))}
				<input type={"submit"} className={"section6-contact-button"} value={'Submit'}/>
			</form>
		</div>
	);
}

export default Section6;
