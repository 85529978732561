import React from "react";
import "./Section3.scss";
import Section3SmallCard from "../molecules/section3/Section3SmallCard";
import { Section3SmallCardData } from "../../../data/main/section3SmallCardData";
import Section3WhisperCard from "../molecules/section3/Section3WhisperCard";
import Section3LargeCard from "../molecules/section3/Section3LargeCard";
import {Section3LargeCardData, Section3WhisperCardData} from "../../../data/main/section3LargeCardData";

function Section3(props) {
  return (
    <div id="Section3">
      <div className="section3-title-wrapper">
        <div className="section3-title-main">Solution</div>
        <div className="section3-title-sub">Start your business quickly with EQ Hub's proven Web3 solution. We provide everything you need, including APIs, Smart Contracts, Dashboard etc.</div>
      </div>
      <div className="section3-card-container">
        {
          Section3WhisperCardData.map((cardData,index)=>{
            return <Section3WhisperCard key={`card-whisper-3-${index}`} cardData={cardData}/>
          })
        }
        <div className="section3-small-card-inner-container">
          {Section3LargeCardData.map((cardData, index) => {
            return <Section3LargeCard key={`card-large-3-${index}`} cardData={cardData} />;
          })}
        </div>
      </div>
    </div>
  );
}

export default Section3;
