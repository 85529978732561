import React from "react";
import "./Section5Card.scss";
import Section5_Card_Image from "../../../../common/asset/images/Section5_Developer_Card_Image.png";
import Link_Button_White from "../../../../common/asset/images/Link_Button_White.png";

function Section5Card(props) {
  const openEqhub = () => {
    window.open(process.env.REACT_APP_EQHUB_URL)
  }
  return (
    <div id="Section5Card">
      <div className="section5-card-content">
        <div className="section5-card-content-text">
          {
            "EQ Hub collaborates with global enterprises to promote Web3 adoption and blockchain commercialization. We provide various blockchain-based solutions including STO, Web3 payments, points, and vouchers, and welcome new partnerships."
          }
        </div>
      </div>
      <img
        className="section5-card-image"
        src={Section5_Card_Image}
        alt=""
      />
    </div>
  );
}

export default Section5Card;
