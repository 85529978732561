import React from "react";
import "./Section2.scss";
import Section2Card from "../molecules/section2/Section2Card";
import { section2CardData } from "../../../data/main/section2CardData";

function Section2(props) {
    return (
        <div id="Section2">
          <div className="section2-title-wrapper">
            <div className="section2-title-main">Full support for DApp developers</div>
            <div className="section2-title-sub">Take full advantage of the resources you need for development.</div>
          </div>
            <div className="section2-card-container">
                {section2CardData.map((cardData, index) => {
                    return <Section2Card key={index} cardData={cardData} />;
                })}
            </div>
        </div>
    );
}

export default Section2;
