import Section2CardImage1 from "../../common/asset/images/Section2_Card_Image1.png";
import Section2CardImage2 from "../../common/asset/images/Section2_Card_Image2.png";
import Section2CardImage3 from "../../common/asset/images/Section2_Card_Image3.png";

export const section2CardData = [
    {
        title: 'FAST & STABLE',
        image: Section2CardImage1,
        mainText: 'Contract Library',
        description: 'Deploy and use the various smart contract templates provided with No-Code.  Feel free to utilize templates created for each business purpose.'
    },
    {
        title: 'REDUCED TIME & COST',
        image: Section2CardImage2,
        mainText: 'Chain Requests',
        description: 'In Chain Request, easily execute all the functions of the smart contract you deployed, and receive the results.\n' +
            'No need to do it in IDE.'
    },
    {
        title: 'ENHANCED SCALABILITY & VALUE',
        image: Section2CardImage3,
        mainText: 'EQ Hub API Docs & Guide',
        description: 'Refer to the API Docs and Guides, which provide detailed information on how to properly use the API, various functions provided by EQ Hub.'
    }
]