import Link_Button_Blue from "../../common/asset/images/Link_Button_Blue.png";
import Section3_DApp_Card_Image from "../../common/asset/images/Section3_DApp_Card_Image.png";
import Section3_Airdrop_Card_Image from "../../common/asset/images/Section3_Airdrop_Card_Image.png";
import WhisperCardImg1 from "../../common/asset/images/whisperCardImg1.png"
import WhisperCardImg2 from "../../common/asset/images/whisperCardImg2.png"
import section3LargeCardImg1 from "../../common/asset/images/section3LargeCardImg1.png";
import section3LargeCardImg2 from "../../common/asset/images/section3LargeCardImg2.png";
export const Section3LargeCardData = [
    {
        title: "Ticket & Voucher",
        description: `Leverage usage data for your business with full transparency.`,
        cardImage: section3LargeCardImg1,
    },
    {
        title: "Point",
        description:
            "Boost your business with point-related smart contracts.",
        cardImage: section3LargeCardImg2,
    },
];

export const Section3WhisperCardData = [
    {
        titleImg:WhisperCardImg1,
        textMain:'Fast and Affordable',
        textSub:'Web3 Pay',
    },
    {
        titleImg:WhisperCardImg2,
        textMain:'Fund raise with tokenization',
        textSub:'STO',
    }
]