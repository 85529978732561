import React from 'react';
import Section5Card from "../molecules/section5/Section5Card";
import './Section5.scss'
function Section5(props) {
    return (
        <div id='Section5'>
          <div className="section5-title-wrapper">
            <div className="section5-title-main">Run your Web3 business with EQ Hub</div>
            <div className="section5-title-sub">Customized solutions and various support for enterprises.</div>
          </div>
            <Section5Card/>
        </div>
    );
}

export default Section5;