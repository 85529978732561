import React from "react";
import "./Section4LargeCard.scss";
import Link_Button_White from "../../../../common/asset/images/Link_Button_White.png";

function Section4LargeCard(props) {
  const openEQField = () => {
    window.open(process.env.REACT_APP_EQ_FIELD_URL)
  }
  return (
    <div id="Section4LargeCard">
      <div className="section4-large-card-title">Building blockchain network</div>
      <div className="section4-large-card-content">
        <div className="section4-large-card-main-text" >
          Build and operate your own blockchain network with the blockchain protocol and configuration for your business,  Node management can also be done easily with No-Code.        </div>
      </div>
    </div>
  );
}

export default Section4LargeCard;
