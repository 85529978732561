import React from "react";
import "./Section3LargeCard.scss";
import toast from "react-hot-toast";

function Section3LargeCard({ cardData }) {
  const comingSoonToast = () => {
    toast('Coming soon!', {
      icon: '👏',
    });
  }
  return (
    <div id="Section3LargeCard">
      <div className="section3-large-card-content-wrapper">
        <div className="section3-large-card-text-content">
          <div className="section3-large-card-title">
            {cardData.title}
          </div>
          <div className="section3-large-card-description">
            {cardData.description}
          </div>
        </div>
      </div>
      <img
        //TODO: card asset 변경
        className="section3-large-card-image"
        src={cardData.cardImage}
        alt=""
      />
    </div>
  );
}

export default Section3LargeCard;
