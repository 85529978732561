import React from "react";
import "./Section4.scss";
import Section4LargeCard from "../molecules/section4/Section4LargeCard";
import Section4SmallCard from "../molecules/section4/Section4SmallCard";
import { Section4SmallCardData } from "../../../data/main/section4SmallCardData";

function Section4(props) {
  return (
    <div id="Section4">
      <div className="section4-title-wrapper">
        <div className="section4-title-main">Build a reliable foundation for your Web3 business</div>
        <div className="section4-title-sub">Build your blockchain network with your desired blockchain protocol and configuration, or run a node more easily. EQ Hub Web3 API is included as a matter of course.</div>
      </div>
      <div className="section4-card-container">
        <Section4LargeCard />
        <div className="section4-small-card-container">
          {Section4SmallCardData.map((cardData, index) => {
            return <Section4SmallCard key={`card-small-4-${index}`} cardData={cardData} />;
          })}
        </div>
      </div>
    </div>
  );
}

export default Section4;
