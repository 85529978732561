import Link_Button_Blue from "../../common/asset/images/Link_Button_Blue.png";
import section4SmallCardImage1 from "../../common/asset/images/section4SmallCardImage1.png"

export const Section4SmallCardData = [
	{
		title: 'Major networks access',
		mainText: 'Major networks with large user bases such as Ethereum, Avalanche, etc. are also supported.',
		linkButton: Link_Button_Blue,
		img: section4SmallCardImage1
	},
	{
		title: 'Web3 APIs',
		mainText: 'Our Web3 APIs streamline blockchain operations by automating complex transactions, enabling businesses to efficiently scale with enterprise-grade security.',
		// linkButton: Link_Button_Blue
	}
]
