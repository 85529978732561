import {BrowserRouter} from "react-router-dom";
import RootRouter from "./view/router/RootRouter";
import toast, {Toaster, useToasterStore} from "react-hot-toast";
import {useEffect} from "react";

function App() {
	const {toasts} = useToasterStore();
	const TOAST_LIMIT = 1;

	// Enforce Toast Limit
	useEffect(() => {
		toasts
			.filter((t) => t.visible)
			.filter((_, i) => i >= TOAST_LIMIT)
			.forEach((t) => toast.dismiss(t.id));
	}, [toasts]);
	return (
		<BrowserRouter>
			<RootRouter/>
			<Toaster toastOptions={{dismiss: false}}/>
		</BrowserRouter>
	);
}

export default App;
